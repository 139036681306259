// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-best-timesheet-app-for-construction-js": () => import("./../../../src/pages/best-timesheet-app-for-construction.js" /* webpackChunkName: "component---src-pages-best-timesheet-app-for-construction-js" */),
  "component---src-pages-brightpay-timesheet-integration-js": () => import("./../../../src/pages/brightpay-timesheet-integration.js" /* webpackChunkName: "component---src-pages-brightpay-timesheet-integration-js" */),
  "component---src-pages-calculators-decimal-to-hours-minutes-calculator-js": () => import("./../../../src/pages/calculators/decimal-to-hours-minutes-calculator.js" /* webpackChunkName: "component---src-pages-calculators-decimal-to-hours-minutes-calculator-js" */),
  "component---src-pages-calculators-hours-minutes-to-decimal-calculator-js": () => import("./../../../src/pages/calculators/hours-minutes-to-decimal-calculator.js" /* webpackChunkName: "component---src-pages-calculators-hours-minutes-to-decimal-calculator-js" */),
  "component---src-pages-calculators-hours-to-minutes-calculator-js": () => import("./../../../src/pages/calculators/hours-to-minutes-calculator.js" /* webpackChunkName: "component---src-pages-calculators-hours-to-minutes-calculator-js" */),
  "component---src-pages-calculators-labour-percentage-calculator-js": () => import("./../../../src/pages/calculators/labour-percentage-calculator.js" /* webpackChunkName: "component---src-pages-calculators-labour-percentage-calculator-js" */),
  "component---src-pages-calculators-timesheet-calculator-js": () => import("./../../../src/pages/calculators/timesheet-calculator.js" /* webpackChunkName: "component---src-pages-calculators-timesheet-calculator-js" */),
  "component---src-pages-causeway-donseed-alternative-js": () => import("./../../../src/pages/causeway-donseed-alternative.js" /* webpackChunkName: "component---src-pages-causeway-donseed-alternative-js" */),
  "component---src-pages-clockshark-alternative-js": () => import("./../../../src/pages/clockshark-alternative.js" /* webpackChunkName: "component---src-pages-clockshark-alternative-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-employee-instant-messenger-js": () => import("./../../../src/pages/employee-instant-messenger.js" /* webpackChunkName: "component---src-pages-employee-instant-messenger-js" */),
  "component---src-pages-employee-leave-management-system-js": () => import("./../../../src/pages/employee-leave-management-system.js" /* webpackChunkName: "component---src-pages-employee-leave-management-system-js" */),
  "component---src-pages-free-rota-templates-js": () => import("./../../../src/pages/free-rota-templates.js" /* webpackChunkName: "component---src-pages-free-rota-templates-js" */),
  "component---src-pages-free-timesheet-templates-js": () => import("./../../../src/pages/free-timesheet-templates.js" /* webpackChunkName: "component---src-pages-free-timesheet-templates-js" */),
  "component---src-pages-gardening-landscaping-time-tracking-js": () => import("./../../../src/pages/gardening-landscaping-time-tracking.js" /* webpackChunkName: "component---src-pages-gardening-landscaping-time-tracking-js" */),
  "component---src-pages-gps-timesheets-js": () => import("./../../../src/pages/gps-timesheets.js" /* webpackChunkName: "component---src-pages-gps-timesheets-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moneysoft-timesheet-integration-js": () => import("./../../../src/pages/moneysoft-timesheet-integration.js" /* webpackChunkName: "component---src-pages-moneysoft-timesheet-integration-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-remote-employees-js": () => import("./../../../src/pages/remote-employees.js" /* webpackChunkName: "component---src-pages-remote-employees-js" */),
  "component---src-pages-rota-planner-js": () => import("./../../../src/pages/rota-planner.js" /* webpackChunkName: "component---src-pages-rota-planner-js" */),
  "component---src-pages-sage-payroll-integration-js": () => import("./../../../src/pages/sage-payroll-integration.js" /* webpackChunkName: "component---src-pages-sage-payroll-integration-js" */),
  "component---src-pages-sage-timesheets-alternative-js": () => import("./../../../src/pages/sage-timesheets-alternative.js" /* webpackChunkName: "component---src-pages-sage-timesheets-alternative-js" */),
  "component---src-pages-subprocessors-js": () => import("./../../../src/pages/subprocessors.js" /* webpackChunkName: "component---src-pages-subprocessors-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-timesheet-app-for-cleaning-companies-js": () => import("./../../../src/pages/timesheet-app-for-cleaning-companies.js" /* webpackChunkName: "component---src-pages-timesheet-app-for-cleaning-companies-js" */),
  "component---src-pages-timesheet-software-for-manufacturing-js": () => import("./../../../src/pages/timesheet-software-for-manufacturing.js" /* webpackChunkName: "component---src-pages-timesheet-software-for-manufacturing-js" */),
  "component---src-pages-tsheets-alternative-js": () => import("./../../../src/pages/tsheets-alternative.js" /* webpackChunkName: "component---src-pages-tsheets-alternative-js" */),
  "component---src-pages-visitor-management-js": () => import("./../../../src/pages/visitor-management.js" /* webpackChunkName: "component---src-pages-visitor-management-js" */),
  "component---src-pages-web-clocking-system-js": () => import("./../../../src/pages/web-clocking-system.js" /* webpackChunkName: "component---src-pages-web-clocking-system-js" */),
  "component---src-pages-xero-timesheet-app-js": () => import("./../../../src/pages/xero-timesheet-app.js" /* webpackChunkName: "component---src-pages-xero-timesheet-app-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

